import axios from 'axios';

const optionsFormat = { style: 'currency', currency: 'MXN' };
export const numberFormatMX = new Intl.NumberFormat('es-MX', optionsFormat);
export const percentFormatMX = new Intl.NumberFormat('es-MX', {
  style: 'percent',
});
export const deleteUndefined = (text) => {
  if (text !== undefined) {
    let textUndefined = text.replace(/\| undefined/g, '');
    return textUndefined;
  } else {
    return '';
  }
};

export const getFullName = ({
  primer_nombre: pa,
  segundo_nombre: sn,
  apellido_paterno: ap,
  apellido_materno: am,
}) => {
  const firstName = pa || '';
  const secondName = sn || '';
  const lastName1 = ap || '';
  const lastName2 = am || '';
  return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
};

export const extractDocNameFromURL = (url) => {
  if (url) {
    const test = url.match(/c\/.*\?/);
    if (test.length) {
      const docName = test[0].split('/')[1].split('?')[0];
      return docName ? docName : '';
    } else {
      return '';
    }
  }
  return '';
};

export const createFormDataFromObject = (object) => {
  const newFormData = new FormData();
  for (const key in object) {
    const value = object[key];
    if (value) {
      // console.log('key', key, 'value', object[key]);
      newFormData.append(key, value);
    }
  }
  return newFormData;
};

export const generateUsername = (
  {
    primer_nombre: pn,
    segundo_nombre: sn,
    apellido_paterno: ap,
    apellido_materno: am,
    fecha_nacimiento: fena,
  },
  attemps = 0
) => {
  const snUser = sn !== null && sn.length > 0 ? sn[0] : '';
  const amUser = am !== null && am.length > 0 ? am[0] : '';
  const splitedFena = fena.split('-');
  const numericUser = `${splitedFena[2]}${splitedFena[1]}`;
  const randomNumber = completeNumberWithZeros(getRandomInt(1, 100), 3);

  const userName = `${pn}${snUser}.${ap}${amUser}${numericUser}${randomNumber}`;
  const userNameClean = normalizeText(userName);
  return `${userNameClean.toLowerCase()}${attemps !== 0 ? attemps : ''}`;
};
export const generatePassword = (
  // eslint-disable-next-line no-unused-vars
  { primer_nombre: pn, fecha_nacimiento: fena },
  length = 10
) => {
  // const splitedFena = fena.split('-');
  // const userPassword = `${pn}${splitedFena.join('')}`;
  // const userPasswordClean = normalizeText(userPassword);
  // return userPasswordClean.toLowerCase();
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?';

  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
};
export const generateGenericUsername = (
  { firstName: fn, lastName: ln },
  type = ''
) => {
  const splitedFn = fn.split(' ');

  const now = new Date();
  const timeInMili = now.getTime();
  const timeString = timeInMili.toString();
  const numericUser = timeString.substring(8, timeString.length);

  const fnUser = fn !== null && splitedFn.length > 0 ? splitedFn[0] : type;
  const lnUser = ln !== null && ln.length > 0 ? ln[0] : '';
  const randomNumber = completeNumberWithZeros(getRandomInt(1, 100), 3);

  const userName = `${fnUser}${lnUser}${numericUser}${randomNumber}`;
  const userNameClean = normalizeText(userName);
  return userNameClean.toLowerCase();
};
export const generateGenericPassword = () => {
  const userPasswordClean = `kP_${completeNumberWithZeros(
    getRandomInt(1, 99999),
    5
  )}`;
  return userPasswordClean;
};

/**
 * Remueve los acentos diacriticos y espacios de
 * un texto
 * @param {string} text texto a limpiar
 * @returns texto sin empacios y sin acento
 */
export const normalizeText = (text) => {
  return text
    .replace(/ /g, '')
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const getRandomInt = (min = 1, max = 100) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};

export const completeNumberWithZeros = (number, length) => {
  return String(number).padStart(length, '0');
};

export const downloadFileFromBlob = (blob, fileName = 'download.xls') => {
  try {
    const blobUrl = window.URL.createObjectURL(blob);
    // console.log('blobUrl', blobUrl);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${fileName}`;
    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};

export const downloadFileFromUrl = async (url, fileName = 'download.xls') => {
  const { data } = await axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  });
  const blob = new Blob([data]);

  downloadFileFromBlob(blob, fileName);
};

export const objectHasNulls = (object) => {
  return Object.values(object).some((value) => value === null);
};

/**
 * Obtiene los valores que se encuentren en
 * el array 1 y no en el array 2
 * @param {Array} array1 Primer array
 * @param {Array} array2 Segundo array
 * @returns array con la diferencia
 */
export const getDifference = (array1 = [], array2 = []) => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  const difference = [...set1].filter((item) => !set2.has(item));
  return difference;
};

// Function to get the CSRF token from the cookie
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
