import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';
export const fetchIncomeCategories = async ({
  institutionId = null,
  data = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const dataFilter = data !== null ? `&dato=${data}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/categoria-ingreso?'.concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las categorias de ingreso. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener categorias de ingreso. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener categorias de ingreso. ' + error,
      count: 0,
    };
  }
};

export const fetchIncomeCategory = async (categoryId) => {
  try {
    const response = await getItem(
      `/app-administracion/categoria-ingreso/${categoryId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener categoria de ingreso por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar obtener categoria de ingreso por id. ' + error,
    };
  }
};

export const fetchPaymentMethods = async ({
  institutionId = null,
  data = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const dataFilter = data !== null ? `&dato=${data}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/forma-de-pago?'.concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las formas de pago. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener formas de pago. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener formas de pago. ' + error,
      count: 0,
    };
  }
};

export const fetchPaymentMethod = async (paymentMethodId) => {
  try {
    const response = await getItem(
      `/app-administracion/forma-de-pago/${paymentMethodId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener forma de pago por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener forma de pago por id. ' + error,
    };
  }
};

export const fetchFiltersWithData = async (
  model,
  {
    institutionId = null,
    data = null,
    systemStatus = null,
    limit = 100,
    offset = 0,
  },
  appName = 'app-administracion'
) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const dataFilter = data !== null ? `&dato=${data}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      `/${appName}/filters/${model}?`.concat(
        institutionFilter,
        dataFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: `No se pudieron obtener ${model}. ${e}`,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error(`Error al obtener ${model}`, error);
    return {
      ok: false,
      message: `Error al intentar obtener ${model}. ${error}`,
      count: 0,
    };
  }
};

export const fetchAccounts = async ({
  institutionId = null,
  accountName = null,
  accountNumber = null,
  bankId = null,
  authorId = null,
  systemStatus = null,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const accountNameFilter =
      accountName !== null ? `&nombre_cuenta=${accountName}` : '';
    const accountNumberFilter =
      accountNumber !== null ? `&numero_cuenta=${accountNumber}` : '';
    const bankIdFilter = bankId !== null ? `&banco=${bankId}` : '';
    const authorIdFilter = authorId !== null ? `&autor=${authorId}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/cuenta?'.concat(
        institutionFilter,
        accountNameFilter,
        accountNumberFilter,
        bankIdFilter,
        authorIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las cuentas. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener cuentas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener cuentas. ' + error,
      count: 0,
    };
  }
};

export const fetchAccount = async (accountId) => {
  try {
    const response = await getItem(`/app-administracion/cuenta/${accountId}`);
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e ? e : detail ? detail : 'Error al obtener cuenta por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener cuenta por id. ' + error,
    };
  }
};

export const fetchEducationalInstitutions = async ({
  tradename = null,
  businessName = null,
  taxDataId = null,
  branchNumber = null,
  userNumber = null,
  activationKey = null,
  systemStatus = true,
  limit = 100,
  offset = 0,
}) => {
  try {
    const systemStatusFilter =
      systemStatus !== null ? `estatus_sistema=${systemStatus}` : '';
    const tradenameFilter =
      tradename !== null ? `&nombre_comercial=${tradename}` : '';
    const businessNameFilter =
      businessName !== null ? `&razon_social=${businessName}` : '';
    const taxDataIdFilter =
      taxDataId !== null ? `&datos_fiscales=${taxDataId}` : '';
    const branchNumberFilter =
      branchNumber !== null ? `&num_sucursales=${branchNumber}` : '';
    const userNumberFilter =
      userNumber !== null ? `&num_usuarios=${userNumber}` : '';
    const activationKeyFilter =
      activationKey !== null ? `&clave_activacion=${activationKey}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/institucion-educativa?'.concat(
        systemStatusFilter,
        tradenameFilter,
        businessNameFilter,
        taxDataIdFilter,
        branchNumberFilter,
        userNumberFilter,
        activationKeyFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener instituciones educativas. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener instituciones educativas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener instituciones educativas. ' + error,
      count: 0,
    };
  }
};

export const fetchEducationalInstitution = async (institutionId) => {
  try {
    const response = await getItem(
      `/app-personas/institucion-educativa/${institutionId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener institucion educativa por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar obtener institucion educativa por id. ' + error,
    };
  }
};

export const fetchDebts = async ({
  institutionId = null,
  debitName = null,
  studentId = null,
  discountApplied = null,
  coinId = null,
  statusId = null,
  overdueDate = null,
  penaltyId = null,
  authorId = null,
  systemStatus = true,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionIdFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const debitNameFilter =
      debitName !== null ? `&nombre_adeudo=${debitName}` : '';
    const studentIdFilter = studentId !== null ? `&alumno=${studentId}` : '';
    const discountAppliedFilter =
      discountApplied !== null ? `&descuento_aplicado=${discountApplied}` : '';
    const coinIdFilter = coinId !== null ? `&moneda=${coinId}` : '';
    const statusIdFilter = statusId !== null ? `&estatus=${statusId}` : '';
    const overdueDateFilter =
      overdueDate !== null ? `&fecha_vencimiento=${overdueDate}` : '';
    const penaltyIdFilter =
      penaltyId !== null ? `&penalizacion=${penaltyId}` : '';
    const authorIdFilter = authorId !== null ? `&autor=${authorId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/adeudos?'.concat(
        institutionIdFilter,
        debitNameFilter,
        studentIdFilter,
        discountAppliedFilter,
        coinIdFilter,
        statusIdFilter,
        overdueDateFilter,
        penaltyIdFilter,
        authorIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener adeudos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener adeudos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener adeudos. ' + error,
      count: 0,
    };
  }
};

export const fetchIncomes = async ({
  institutionId = null,
  studentId = null,
  debitId = null,
  categoryId = null,
  penaltyApplied = null,
  discountApplied = null,
  paymentMethodId = null,
  destinationAccountId = null,
  coinId = null,
  statusId = null,
  penaltyId = null,
  authorId = null,
  systemStatus = true,
  limit = 100,
  offset = 0,
}) => {
  try {
    const institutionIdFilter =
      institutionId !== null ? `institucion_educativa=${institutionId}` : '';
    const studentIdFilter = studentId !== null ? `&alumno=${studentId}` : '';
    const debitIdFilter = debitId !== null ? `&adeudo=${debitId}` : '';
    const categoryIdFilter =
      categoryId !== null ? `&categoria=${categoryId}` : '';
    const penaltyAppliedFilter =
      penaltyApplied !== null ? `&penalizacion_aplicada=${penaltyApplied}` : '';
    const discountAppliedFilter =
      discountApplied !== null ? `&descuento_aplicado=${discountApplied}` : '';
    const paymentMethodIdFilter =
      paymentMethodId !== null ? `&forma_de_pago=${paymentMethodId}` : '';
    const destinationAccountIdFilter =
      destinationAccountId !== null
        ? `&cuenta_destino=${destinationAccountId}`
        : '';
    const coinIdFilter = coinId !== null ? `&moneda=${coinId}` : '';
    const statusIdFilter = statusId !== null ? `&estatus=${statusId}` : '';
    const penaltyIdFilter =
      penaltyId !== null ? `&penalizacion=${penaltyId}` : '';
    const authorIdFilter = authorId !== null ? `&autor=${authorId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-administracion/filters/ingreso?'.concat(
        institutionIdFilter,
        studentIdFilter,
        debitIdFilter,
        categoryIdFilter,
        penaltyAppliedFilter,
        discountAppliedFilter,
        paymentMethodIdFilter,
        destinationAccountIdFilter,
        coinIdFilter,
        statusIdFilter,
        penaltyIdFilter,
        authorIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los ingresos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener ingresos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener ingresos. ' + error,
      count: 0,
    };
  }
};

/**
 * Crea un ingreso
 * @param {Object} income objeto con los datos del ingreso a crear
 * @returns
 */
export const postIncome = async (income) => {
  try {
    const response = await postItem('/app-administracion/ingreso', income);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el ingreso. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear ingreso. ' + error,
    };
  }
};

/**
 * Actualiza un ingreso
 * @param {Object} income objeto con los datos del ingreso a actualizar. Debe incluir id
 * @returns
 */
export const putIncome = async (income) => {
  try {
    const { id } = income;
    const response = await putItem(`/app-administracion/ingreso/${id}`, income);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el ingreso. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar ingreso. ' + error,
    };
  }
};

/**
 * Elimina un ingreso
 * @param {Number} id id del ingreso a eliminar
 * @returns
 */
export const delIncome = async (id) => {
  try {
    const response = await deleteItem('/app-administracion/ingreso', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el ingreso. ' + response.e,
      };
    }
    return { ok: true, data: 'Incomeo eliminado exitosamente' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar ingreso. ' + error,
    };
  }
};

/**
 * Actualiza un adeudo
 * @param {Object} debt objeto con los datos del adeudo a actualizar. Debe incluir id
 * @returns
 */
export const putDebt = async (debt) => {
  try {
    const { id } = debt;
    const response = await putItem(`/app-administracion/adeudos/${id}`, debt);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el adeudo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar adeudo. ' + error,
    };
  }
};

export const increaseAccountBalance = async ({
  id_cuenta,
  monto,
  id_autor,
}) => {
  try {
    const url = '/app-administracion/helpers/crear-aumentar-balance-cuenta';
    const response = await postItem(url, {
      id_cuenta,
      monto,
      id_autor,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo aumentar al balance de cuenta. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar aumentar el balance de cuenta. ' + error,
    };
  }
};
export const decreaseAccountBalance = async ({
  id_cuenta,
  monto,
  id_autor,
}) => {
  try {
    const url = '/app-administracion/helpers/crear-disminuir-balance-cuenta';
    const response = await postItem(url, {
      id_cuenta,
      monto,
      id_autor,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo decremetar al balance de cuenta. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar decrementar el balance de cuenta. ' + error,
    };
  }
};

export const sendProofOfIncome = async (incomeId) => {
  try {
    const url = '/app-administracion/helpers/enviar-comprobante-ingreso';
    const response = await postItem(url, {
      id_ingreso: incomeId,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo enviar el comprobante de ingreso. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar enviar el comprobante de ingreso. ' + error,
    };
  }
};

export const postCreateIncomeBill = async (incomeId) => {
  try {
    const url = '/app-facturacion/create-factura-ingreso';
    const response = await postItem(url, {
      id_ingreso: incomeId,
    });
    if (response.e || response.error) {
      return {
        ok: false,
        message:
          'No se pudo crear la factura del ingreso. ' +
          (response.e || response.mensaje),
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear la factura del ingreso. ' + error,
    };
  }
};

/** Respaldo de catalogos SAT, obtenidos desde facturama */
export const FiscalRegimen = [
  {
    Natural: false,
    Moral: true,
    Name: 'General de Ley Personas Morales',
    Value: '601',
  },
  {
    Natural: false,
    Moral: true,
    Name: 'Personas Morales con Fines no Lucrativos',
    Value: '603',
  },
  {
    Natural: true,
    Moral: false,
    Name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
    Value: '605',
  },
  { Natural: true, Moral: false, Name: 'Arrendamiento', Value: '606' },
  {
    Natural: true,
    Moral: false,
    Name: 'Régimen de Enajenación o Adquisición de Bienes',
    Value: '607',
  },
  { Natural: true, Moral: false, Name: 'Demás ingresos', Value: '608' },
  {
    Natural: true,
    Moral: true,
    Name: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
    Value: '610',
  },
  {
    Natural: true,
    Moral: false,
    Name: 'Ingresos por Dividendos (socios y accionistas)',
    Value: '611',
  },
  {
    Natural: true,
    Moral: false,
    Name: 'Personas Físicas con Actividades Empresariales y Profesionales',
    Value: '612',
  },
  { Natural: true, Moral: false, Name: 'Ingresos por intereses', Value: '614' },
  {
    Natural: true,
    Moral: false,
    Name: 'Régimen de los ingresos por obtención de premios',
    Value: '615',
  },
  {
    Natural: true,
    Moral: false,
    Name: 'Sin obligaciones fiscales',
    Value: '616',
  },
  {
    Natural: false,
    Moral: true,
    Name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    Value: '620',
  },
  { Natural: true, Moral: false, Name: 'Incorporación Fiscal', Value: '621' },
  {
    Natural: false,
    Moral: true,
    Name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    Value: '622',
  },
  {
    Natural: false,
    Moral: true,
    Name: 'Opcional para Grupos de Sociedades',
    Value: '623',
  },
  { Natural: false, Moral: true, Name: 'Coordinados', Value: '624' },
  {
    Natural: true,
    Moral: false,
    Name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    Value: '625',
  },
  {
    Natural: true,
    Moral: true,
    Name: 'Régimen Simplificado de Confianza',
    Value: '626',
  },
];

export const Currencies = [
  { Decimals: 2.0, PrecisionRate: 35.0, Name: 'Peso Mexicano', Value: 'MXN' },
];

export const PaymentForms = [
  { Name: 'Efectivo', Value: '01' },
  { Name: 'Cheque nominativo', Value: '02' },
  { Name: 'Transferencia electrónica de fondos', Value: '03' },
  { Name: 'Tarjeta de crédito', Value: '04' },
  { Name: 'Monedero electrónico', Value: '05' },
  { Name: 'Dinero electrónico', Value: '06' },
  { Name: 'Vales de despensa', Value: '08' },
  { Name: 'Dación en pago', Value: '12' },
  { Name: 'Pago por subrogación', Value: '13' },
  { Name: 'Pago por consignación', Value: '14' },
  { Name: 'Condonación', Value: '15' },
  { Name: 'Compensación', Value: '17' },
  { Name: 'Novación', Value: '23' },
  { Name: 'Confusión', Value: '24' },
  { Name: 'Remisión de deuda', Value: '25' },
  { Name: 'Prescripción o caducidad', Value: '26' },
  { Name: 'A satisfacción del acreedor', Value: '27' },
  { Name: 'Tarjeta de débito', Value: '28' },
  { Name: 'Tarjeta de servicios', Value: '29' },
  { Name: 'Aplicación de anticipos', Value: '30' },
  { Name: 'Intermediarios', Value: '31' },
  { Name: 'Por definir', Value: '99' },
];

export const CfdiTypes = [
  { NameId: 1, Name: 'Factura', Value: 'I' },
  { NameId: 2, Name: 'Nota de Crédito', Value: 'E' },
  { NameId: 14, Name: 'Complemento de pago', Value: 'P' },
  { NameId: 17, Name: 'Factura con IEPS', Value: 'I' },
  { NameId: 21, Name: 'Factura INE', Value: 'I' },
  { NameId: 22, Name: 'Honorarios INE', Value: 'I' },
  { NameId: 23, Name: 'Recibo de Arrendamiento INE', Value: 'I' },
  { NameId: 24, Name: 'Factura Detallista', Value: 'I' },
  { NameId: 25, Name: 'Factura para Notarios Publicos', Value: 'I' },
  { NameId: 26, Name: 'Factura Comercio Exterior', Value: 'I' },
  { NameId: 27, Name: 'Nota de Crédito Comercio Exterior', Value: 'E' },
  { NameId: 28, Name: 'Carta Porte Comercio Exterior', Value: 'T' },
  { NameId: 29, Name: 'Factura para terceros', Value: 'I' },
  { NameId: 30, Name: 'Factura Leyendas Fiscales', Value: 'I' },
  { NameId: 34, Name: 'Factura Global', Value: 'I' },
  { NameId: 36, Name: 'Complemento Carta Porte 3.1', Value: 'T' },
];

export const PaymentMethods = [
  { Name: 'Pago en parcialidades ó diferido', Value: 'PPD' },
  { Name: 'Pago en una sola exhibición', Value: 'PUE' },
];
/** ******************************************** */

/**
 * Actualiza un dato fiscal
 * @param {Object} taxInfo objeto con los datos del dato fiscal a actualizar. Debe incluir id
 * @returns
 */
export const putTaxInfo = async (taxInfo) => {
  try {
    const { id } = taxInfo;
    const response = await putItem(
      `/app-personas/datos-fiscales/${id}`,
      taxInfo
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el adeudo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar adeudo. ' + error,
    };
  }
};
