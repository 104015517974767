import axios from 'axios';
import store from '../store';
import { ENVIRONMENT } from './config';
import { getCookie } from '../modules/shared/helpers/dataUtils';

//const APIUrl = 'https://kn.e32.tech'
// const APIUrl = 'https://keystone.kinderplus.mx';
const APIUrl =
  ENVIRONMENT === 'prod'
    ? 'https://kindernino.kinderplus.mx' // backend de la nueva instancia para KN v2
    : 'http://127.0.0.1:8000';

const api = axios.create({
  baseURL: APIUrl,
  headers: { contenttype: 'application/json' },
});

const commonAPI = axios.create({
  baseURL: APIUrl,
  headers: { contenttype: 'application/json' },
});

api.interceptors.request.use(
  (config) => {
    const csrfToken = getCookie('csrftoken');
    const headers =
      ENVIRONMENT === 'prod'
        ? {
            Authorization: `JWT ${store.state.apiTokens.access}`,
            'X-CSRFToken': csrfToken,
          }
        : { Authorization: `JWT ${store.state.apiTokens.access}` };
    config.headers = { ...headers };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await store.dispatch('refreshToken');

      originalRequest.headers[
        'Authorization'
      ] = `JWT ${store.state.apiTokens.access}`;
      console.log('headers', originalRequest.headers);

      return api.request(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { api, APIUrl, commonAPI };
