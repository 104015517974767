export const UserTypes = {
  SUPERADMIN: 1,
  APP_ADMIN_EMPLOYEE: 2,
  SCHOOLCONTROL_EMPLOYEE: 3,
  OPERATIONAL_COORDINATOR_EMPLOYEE: 4,
  PRIMARIA_COORDINATOR_EMPLOYEE: 5,
  SECUNDARIA_COORDINATOR_EMPLOYEE: 6,
  BACHILLER_COORDINATOR_EMPLOYEE: 7,
  SECUNDARIA_BACHILLER_COORDINATOR_EMPLOYEE: 18,
  KINDER_COORDINATOR_EMPLOYEE: 8,
  ACCOUNTING_EMPLOYEE: 9,
  ACCOUNTING_ASSISTANT_EMPLOYEE: 10,
  COLLECTION_EMPLOYEE: 11,
  ADMINISTRATIVE_EMPLOYEE: 12,
  ADMINISTRATIVE_ASSISTANT_EMPLOYEE: 13,
  TEACHER_EMPLOYEE: 14,
  TUTOR: 15,
  STUDENT: 16,
  SUPPLIER: 17,
};

export const AccessLevels = {
  SUPERADMIN: 1,
  SUPER_SCHOOLCONTROL_EMPLOYEE: 2,
  PRIMARIA_SCHOOLCONTROL_EMPLOYEE: 3,
  SECUNDARIA_SCHOOLCONTROL_EMPLOYEE: 3,
  BACHILLER_SCHOOLCONTROL_EMPLOYEE: 3,
  ADMINISTRATIVE_EMPLOYEE: 4,
  ADMINISTRATIVE_ASSISTANT_EMPLOYEE: 5,
  ACCOUNTING_EMPLOYEE: 2,
  ACCOUNTING_ASSISTANT_EMPLOYEE: 3,
  TEACHER_EMPLOYEE: 6,
  TUTOR: 11,
  STUDENT: 12,
  SUPPLIER: 13,
};

export const Actions = {
  view: 'view',
  add: 'add',
  change: 'change',
  delete: 'delete',
};
